import { Dictionary, Number, Record as RRecord, Static } from "runtypes";

import { KernelSize } from "../kernelConfigTypes";
import { typedObjectValues } from "../utils";
export const PrewarmedSizeCounts = Dictionary(Number, KernelSize);
export type PrewarmedSizeCounts = Static<typeof PrewarmedSizeCounts>;

export const PrewarmedDefaultInfo = RRecord({
  numberOfPrewarmedImages: Number,
  missingSizePrewarmedPodCount: Number,
  prewarmSizes: PrewarmedSizeCounts,
});

export type PrewarmedDefaultInfo = Static<typeof PrewarmedDefaultInfo>;

export function getZeroedKernelSizes(): PrewarmedSizeCounts {
  return {
    xsmall: 0,
    small: 0,
    medium: 0,
    large: 0,
    xlarge: 0,
    "2xlarge": 0,
    "4xlarge": 0,
    v100: 0,
    v100x8: 0,
  };
}

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const NoPrewarmedImages = PrewarmedDefaultInfo.check({
  numberOfPrewarmedImages: 0,
  missingSizePrewarmedPodCount: 0,
  prewarmSizes: getZeroedKernelSizes(),
});

export function isPrewarmedInternal(
  prewarmedSizes: PrewarmedSizeCounts,
): boolean {
  return typedObjectValues(prewarmedSizes).some(
    (prewarmedNumber) => prewarmedNumber > 0,
  );
}
